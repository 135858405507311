import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import dash from "../assets/img/dash.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Link } from "react-router-dom";
import axios from "axios";
import { Button } from 'react-bootstrap';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toRotate = ["Application?", "Data?", "Network?"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  };
  const handleInputChange = (e) => {
    setUrl(e.target.value);
  };

  const handleStart = () => {
    if (!url) return;
  
    setIsLoading(true);
  
    // Fire-and-forget POST request
    axios
      .post('https://api.zerodayops.com/api/analysis', { domain: url })
      .catch((error) => {
        console.error("Error occurred during the POST request:", error);
        // Optionally alert the user, though note that the page will already be redirecting.
      });
  
    // Immediately redirect to the next page
    window.location.href = `/reconnaissance/${url}`;
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleStart();
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <span className="tagline">Zero-Day</span>
                  <h2>{`How Secure is Your `}
                    <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Application?", "Data?", "Network?" ]'>
                      <span className="wrap">{text}</span>
                    </span>
                  </h2>
                  <h1>View Critical Risks in Under 30 Seconds.</h1>

                  {/* Search Bar Component */}
                  <div id="search-container">
                    <div id="main">
                      <input className="input" type="text" value={url} onChange={handleInputChange} onKeyPress={handleKeyPress} placeholder="example.com" />
                      {/* <div id="input-mask"></div> */}
                      {/* <div id="cosmic-glow"></div> */}
                    </div>
                    <div className="stardust"></div>
                    <div className="cosmic-ring"></div>
                    <div className="starfield"></div>
                  </div>
                  {/* Scan Button placed next to input */}
                  <button className="scan-button" type="submit" onClick={handleStart}>
                    <svg className="search-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>
                    Scan
                  </button>
                </div>
              }
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={dash} alt="Dashboard Illustration" />
                </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>

  );
};
