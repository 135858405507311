import React, { useState } from "react";
import axios from "axios";
import './recon.css';
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import ScannerFeatures from "../../components/Recon-lower";
import wolf from "../../assets/tools/wolf.webp";

const ReconSearch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [scanStarted, setScanStarted] = useState(false);

  const handleInputChange = (e) => {
    setUrl(e.target.value);
  };

  const handleStart = () => {
    if (!url) return;
  
    setIsLoading(true);
  
    // Fire-and-forget POST request
    axios
      .post('https://api.zerodayops.com/api/analysis', { domain: url })
      .catch((error) => {
        console.error("Error occurred during the POST request:", error);
        // Optionally alert the user, though note that the page will already be redirecting.
      });
  
    // Immediately redirect to the next page
    window.location.href = `/reconnaissance/${url}`;
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleStart();
    }
  };

  return (<>
    <NavBar />
    <div className="scanner-container">
      <div className="scanner-left">
        <img src={wolf} alt="wolf logo" className="scanner-wolf-logo" />
        <h1 className="scanner-title">
          Basic <span className="scanner-highlight">Vulnerability</span> Scanner
        </h1>
        <p className="scanner-description">
          It checks for basic network vulnerabilities, maps the network path to the target through traceroute, and analyzes HTTP security headers. The tool also assesses SSL certificate configurations, scans for sensitive directories, identifies the technologies in use, and enumerates all accessible URLs on the target, providing a thorough understanding of its security posture.
        </p>
      </div>
      <div className="scanner-right">
        <div className="scanner-input-container">
          <label htmlFor="target" className="scanner-input-label">Target</label>
          <input type="text" id="target" className="scanner-input" placeholder="www.example.com" value={url} onKeyPress={handleKeyPress} onChange={handleInputChange}/>
          <button className="scanner-button" onClick={handleStart}>Start scan</button>
        </div>
      </div>
    </div>
    <ScannerFeatures />
    <Footer />
  </>
  );
};

export default ReconSearch;
